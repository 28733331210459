import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import './Header.css'
const SectionSeperator = require("../../images/banner3.svg");
export const Header = () => {
    return (
        <section className="section header">
            <div className="mainSectionWrapper">
                <div className="mainSectionTextContainer">
                    <span className="subTitle">Ein Projekt, Drei Staaten, Unendliche Möglichkeiten!</span>
                    <span className="mainTitle"><span>Trio</span>State</span>
                </div>
            </div>
            <LazyLoadImage src={SectionSeperator} alt="Sektionstrenner" />
        </section>
    )
}