import React from 'react';
import './SocialLink.css'

interface Props {
  link: string,
  iconClass: string,
  socialPlattform: string,
  socialIdName: string;
}

export const SocialLink = ({ link, iconClass, socialPlattform, socialIdName }: Props) => {
  return (
    <a href={link} aria-label={socialPlattform} target="_blank" className="socialLink">
      <i className={iconClass}></i>
      <div className="mainSocialTextBox">
        <span>{socialPlattform}</span>
        <span>{socialIdName}</span>
      </div>
    </a>
  )
}


export default SocialLink