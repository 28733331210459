import React from 'react'
import { SectionOne } from './sectionOne/SectionOne'
import { SectionTwo } from './sectionTwo/sectionTwo'
import { SectionThree } from './sectionThree/sectionThree'
import { VideoSection } from './videosection/VideoSection'
import { Layout } from './layout/Layout'


export const SectionLoader = () => {
    return (
        <Layout>
        <SectionOne/>
        <VideoSection/>
        <SectionTwo/>
        <SectionThree/>
        
        </Layout>
    )
}
