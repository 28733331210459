import React from 'react'
import './VideoSection.css'
export const VideoSection = () => {
    return (
        <section>
            <div className="wrapper">
                <iframe src="https://www.youtube.com/embed/DMdt_i4boFc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen loading="lazy"></iframe>
            </div>
        </section>
    )
}