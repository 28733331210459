import React from "react"
import { Button } from "../Button/Button"
import { LazyLoadImage } from 'react-lazy-load-image-component'
import BannerTop from '../../images/bannertop.svg'
import './Footer.css'

const FooterLogo = require("../../images/TrioLogoWhite.png");
const DiscordIcon = require("../../images/icons/discord.svg");
const TeamspeakIcon = require("../../images/icons/teamspeak.svg");
const TiktokIcon = require("../../images/icons/tiktok.svg");
const YoutubeIcon = require("../../images/icons/youtube.svg");
const TwitterIcon = require("../../images/icons/twitter.svg");

export const Footer = () => {
    return (
        <section className="trioMainSection4">
            <LazyLoadImage src={BannerTop} alt="Sektionstrenner" />
            <div className="trioPreFooter">
                <div className="footerSectionWrapper">
                    <div className="footerSectionTextContainer">
                        Worauf wartest du noch?<br />
                        Lass dich noch heute Whitelisten!
                    </div>
                    <div className="mainButtonContainer">
                        <Button buttonText="zur Whitelist" link="https://go.triostate.net/whitelist" disabled={false} />
                    </div>
                </div>
            </div>
            <div className="trioFooterBox">
                <div className="trioFooterWrapper">
                    <div className="trioFooterLogo">
                        <img src={FooterLogo} alt="logo2" />
                        <div className="trioFooterSocials">
                            <a href="https://discord.gg/triostate" aria-label="Discord Link" target="_blank" ><LazyLoadImage src={DiscordIcon} alt="Discord Link" width="20px" height="20px" /></a>
                            <a href="ts3server://triostate.net" aria-label="Teamspeak connect Link" target="_blank" ><LazyLoadImage src={TeamspeakIcon} alt="Teamspeak connect Link" width="20px" height="20px" /></a>
                            <a href="https://tiktok.com/@triostate" aria-label="Tiktok Link" target="_blank" ><LazyLoadImage src={TiktokIcon} alt="Tiktok Link" width="20px" height="20px" /></a>
                            <a href="https://youtube.com/@triostateroleplay" aria-label="Youtube Link" target="_blank" ><LazyLoadImage src={YoutubeIcon} alt="Youtube Link" width="20px" height="20px" /></a>
                            <a href="https://twitter.com/TrioStateRP" aria-label="Twitter Link" target="_blank" ><LazyLoadImage src={TwitterIcon} alt="Twitter Link" width="20px" height="20px" /></a>
                        </div>
                    </div>
                    <div className="trioFooterNavigation">
                        <ul>
                            <li><a href="https://go.triostate.net/whitelist" aria-label="Triostate Whitelist Link">Whitelist</a></li>
                            <li><a href="https://go.triostate.net/rules" aria-label="Triostate Rules Link">Regelwerk</a></li>
                            <li><a href="https://forum.triostate.net" aria-label="Triostate Forum">Forum</a></li>
                            <li><a href="https://status.triostate.net" aria-label="Triostate Status Link">Serverstatus</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="trioFooterBottomNav">
                <div className="trioFooterBotNavRights">© TrioState 2024</div>
                <div className="trioFooterBotNav"><span><a href="/impressum" aria-label="Impressum Link">Impressum</a></span><span><a href="/impressum" aria-label="Datenschutz Link">Datenschutz</a></span></div>
            </div>
        </section>
    )
}