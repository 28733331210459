import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import './ContentCard.css'

interface ContentObject {
    [key: string]: string;
}

interface Props {
    img: string,
    title: string,
    reverse: boolean,
    slogan: string,
    content: ContentObject
}
export const ContentCard = ({
    img,
    title,
    reverse,
    slogan,
    content
}: Props) => {
    return (
        <div className={reverse ? 'contentCard reverse' : 'contentCard'}>
            <div className="imgContainer">
                <LazyLoadImage src={img} alt="Content Vorschau" />
            </div>

            <div className={reverse ? 'contentText contentTextRight' : 'contentText'}>
                <div className="sloganBox">
                    <h2>{title}</h2>
                    <p className="slogan">{slogan}</p>
                </div>
                <div className="content">
                    {Object.entries(content).map(([title, paragraph], index) => (
                        <div key={index}>
                            <h4>{title}</h4>
                            <p>{paragraph}</p>
                        </div>
                    ))}

                </div>
            </div>
        </div>
    )
}