import React from 'react';
import { ContentCard } from "../../../modules/ContentCard/ContentCard";
import './sectionThree.css'

const biz = require('../../../images/biz.png');
const crime = require('../../../images/crime.jpg');
const state = require('../../../images/state.jpg');

export const SectionThree = () => {
    const bizSloganText: string = 'In manchen von uns steckt der wahre Geschäftsmann, der Weg zum Ziel beginnt an dem Tag, an dem du die hundertprozentige Verantwortung für dein Tun übernimmst. Ein echter Unternehmer ist jemand, der kein Sicherheitsnetz unter sich hat. Dir stehen alle Türen der Geschäftswelt offen, du musst den ersten Schritt wagen - 100 Immobilien warten auf dich, starte dein Business - werde erfolgreich!'
    const crimeSloganText: string = '"Silence is better than Bullshit" - Das Leben auf der Straße, manche kommen damit klar - manche bringt es um. Das Leben läuft nicht immer so, wie es sollte. Oder du wirst in den Scheiß rein geboren. Da hilft auch nicht der liebe Gott, für Sünden ist es zu spät. Das wahre Leben ruft. Drogen, Waffen, Frauen & schnelle Autos begehrt der Mensch. Der Drang irgendwann aus diesem Dreckloch rauszukommen.'
    const stateSloganText: string = 'Der Staat, unser Freund und auch irgendwie Feind. Manche von uns sind wahre Rebellen, Systemsprenger - andere wiederum, kämpfen für ihren Staat. In der internationalen Politik geht es nie um Demokratie oder Menschenrechte. Es geht um die Interessen von Staaten. Merken Sie sich das, egal, was man Ihnen im Geschichtsunterricht erzählt.'

    const bizContent: any = {
        "Gastronomie": ["Leute haben mich nach dem Geheimnis meiner langen Ehe gefragt. Wir nehmen uns die Zeit und gehen zweimal die Woche in ein Restaurant – ein bisschen Candlelight, gutes Essen, schöne Musik und tanzen. Sie geht Dienstags, ich gehe Freitags. Die Gastronomie blüht auf unserem Server auf, ob typisch amerikanisch beim Chicken Bistro oder mal einen warmen Kaffee am Morgen im Café Bean? Die Gastronomie bietet bis heute eine Menge Arbeitsplätze für junge, angehende Gastronomen."],
        "Automobilbranche": ["Die drei bekanntesten Generäle in Amerika sind immer noch General Motors, General Electric und General Food. Wer liebt bitte nicht das Geräusch eines brummenden Motors oder den Duft eines richtigen Auspuffs? Von Los Santos bis nach Blaine County hoch, ist das Land mit zahlreichen Händlern bestückt. Du hast ein Scheinchen zu viel in der Geldtasche, ab zu Benefactor! Oder stehst du auf richtige Motoren? Los gehts zu Larry's Rv Sales, Qualität seit 1971!"],
        "Körperpflege & 24/7": ["Du sehnst dich nach einer neuen Tätowierung oder einer neuen Frisur? Selbstverständlich möchten wir dir da nicht im Weg stehen, viele Unternehmen werden von Spielern betrieben. Du stehst im ständigen Kontakt mit deinem Lieblingsfriseur und kannst dich beim monatlichen Schnitt über die aktuelle Politik unterhalten. Ob Tankstelle oder 24/7 Laden, du triffst immer auf den Besitzer und seine Mitarbeiter. Ob Pornoheft oder Klopapier, in solchen Läden lässt sich so manches finden."]
    };
    const crimeContent: any = {
        "Streetlife & Gangs": ["Von Anfang an unterwegs mit den Jungs, leb' und sterb' nur als Dealer. Mama sagt: 'Hab Geduld, geh nicht raus, Gott gibt, wenn du Ziel hast! Aber Mama, wie lang? - Wirst du einmal hier geboren, bleibst du auch hier. Das Leben wartet auf dich, durch eine große Vernetzung an vielfältigen Sets & Cliquen kommt es nicht selten zum Konflikt und spannenden Auseinandersetzungen. Dies ist ein Geschäft, was wir uns selber ausgesucht haben."],
    };
    const stateContent: any = {
        "Politik": ["Moderne Politik ist im Grunde ein Kampf nicht von Menschen sondern Kräften. Auf unserem Server bieten wir für jeden Politikinteressierten Mensch eine passende Stelle, ob als Senator oder Senatorin oder gleich Gouverneur? Wir möchten in diese Richtung aktives als auch passives Roleplay bieten, was es so noch nie gab. Eigener Wahlkampf, Mitbestimmung oder Sitzungen - die Politik bietet viele Möglichkeiten."],
        "LSPD, BCSO & LSFD": ["Selbstverständlich muss unser Server an genügend Lebensrettern und Staatshelden verfügen. Das Los Santos Police Department und das Los Santos Fire Department bieten einen direkten Anlauf für solchen Ruhm. Ob du jetzt dem Verbrecher die Gasse folgst, an der großen Grenze stehst oder Strafzettel verteilst, es wird nie langweilig als als Police Officer. Die Operation assistieren, den Baum in Teile zu sägen, um ihn von der Straße zu transportieren oder als Paramedic im Krankenwagen umher zusausen, es gibt genug Arbeit."],
        "DOJ & DOC": ["Selbstverständlich möchten wir unseren fleißigen Juristen und Gesetzbuchleser auch ein spannendes Roleplay bieten. Getreu nach dem amerikanischen Rechtssystem, gestalten wir für das Department of Justice ein Roleplay was in alle Richtungen glänzt. Nach der Gerichtsverhandlung geht es direkt ins Gefängnis, ab jetzt gehörst du dem Department of Corrections an. Das Geschehen im Gefängnis kann spannend und vielfältig sein, das garantieren wir auf unserem Server."]
    };
    return (
        <section>
            <div className="wrapper">
                <div className="posibilitys">
                    <ContentCard slogan={bizSloganText} content={bizContent} img={biz} title="Business" reverse={false} />
                    <ContentCard slogan={crimeSloganText} content={crimeContent} img={crime} title="Crime" reverse={true} />
                    <ContentCard slogan={stateSloganText} content={stateContent} img={state} title="Staat" reverse={false} />
                </div>
            </div>
        </section>
    )
}