import { Button } from "../../../modules/Button/Button"
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { SocialLink } from "../../../modules/SocialLinks/SocialLink"
import './SectionOne.css'
import React from 'react'

const SectionSeperator = require("../../../images/banner3.svg");


export const SectionOne = () => {

  return (
    <section className="section sectionOne bg-white">
      <div className="mainSectionWrapper">
        <div className="mainSectionTextContainer">
          <span className="subTitle">Ein Projekt, Drei Staaten, Unendliche Möglichkeiten!</span>
          <span className="mainTitle"><span>Trio</span>State</span>
        </div>
        <div className="mainButtonContainer">
          <Button buttonText="Serverstatus" link="https://status.triostate.net" disabled={false} />
        </div>
        <div className="mainSocialMediaHolder">
          <SocialLink link="https://discord.gg/triostate" iconClass="fa-brands fa-discord" socialPlattform="Discord" socialIdName="TrioState" />
          <SocialLink link="https://tiktok.com/@triostate" iconClass="fa-brands fa-tiktok" socialPlattform="TikTok" socialIdName="TrioState" />
          {/* <SocialLink link="https://youtube.com/@triostateroleplay" iconClass="fa-brands fa-youtube" socialPlattform="YouTube" socialIdName="TrioStateRoleplay" /> */}
          <SocialLink link="https://www.twitch.tv/triostate" iconClass="fa-brands fa-twitter" socialPlattform="Twitch" socialIdName="TrioStateRP" />
        </div>
      </div>
      <LazyLoadImage src={SectionSeperator} alt="Sektionsteiler" />
    </section >
  )
}